import React, { useContext, useEffect, useState } from 'react';
import * as S from './ActiveSubscriptionSelectorV2.styles';
import axios from 'axios';
import Select, { components } from 'react-select';
import FunnelContext from '../../context/FunnelContext';
import ConfirmationPopup from './ConfirmationPopup/ConfirmationPopup';
import { Builder } from '@builder.io/react';
import { addDataLayer } from '../../utils/dataLayer';
import LoadingCover from '../LoadingCover/LoadingCover';

const getParamFromQuery = key => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key) || '';
};

const ActiveSubscriptionSelector = props => {
  const {
    children,
    expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
    redirectIfAuthFails = false,
    endpointApiSite = 'https://qa2-subscription.thepetlabco.uk',
    petlabCampaignTokenKey = 'petlab_campaign_token',
    klaviyoUserIdKey = 'utm_id',
    klaviyoCampaignIdKey = 'utm_campaign',
    variantExistsMessage,
    accountPortalUrl = `https://account.thepetlabco.com/dashboard`,
    addLoadingCover = false,
    topText,
    confirmationPopupPricingAddonOnly = false
  } = props;

  const [subscriptionList, setSubscriptionList] = useState(null);
  const [token, setToken] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [submitted, setSubmitted] = useState([]);
  const [loadingCover, setLoadingCover] = useState(addLoadingCover);

  const {
    currency,
    subscription,
    currentTubIndex,
    extraObjects,
    currentCategory,
    setExtraObjects
  } = useContext(FunnelContext);

  const currentProductData = subscription.products?.find(
    (_, index) => index === currentTubIndex
  );
  const [subDiscountCode] = subscription.discounts;

  const currencySymbol = currency?.symbol || '$';

  useEffect(() => {
    if (!subscriptionList) {
      const petlabCampaignTokenValue = getParamFromQuery(
        petlabCampaignTokenKey
      );
      const klaviyoUserIdValue = getParamFromQuery(klaviyoUserIdKey);
      let klaviyoCampaignIdValue = getParamFromQuery(
        klaviyoCampaignIdKey
      ).match(/\((.*)\)/g);
      if (klaviyoCampaignIdValue) {
        klaviyoCampaignIdValue = klaviyoCampaignIdValue[0] || '';
        klaviyoCampaignIdValue = klaviyoCampaignIdValue
          .replace('(', '')
          .replace(')', '');
      }

      try {
        axios
          .post(`${endpointApiSite}/api/campaign/auth`, {
            petlab_campaign_token: petlabCampaignTokenValue,
            klaviyo_user_id: klaviyoUserIdValue,
            klaviyo_campaign_id: klaviyoCampaignIdValue
          })
          .then(({ data }) => {
            const results = JSON?.parse(data);
            setToken(results['token']);
            axios
              .get(`${endpointApiSite}/api/subscription/list`, {
                headers: {
                  Authorization: `Bearer ${results['token']}`
                }
              })
              .then(({ data: { data } }) => {
                const [firstItem = {}] = data;
                setActiveSubscription(firstItem);
                setSubscriptionList(data);
                setExtraObjects({
                  ...extraObjects,
                  firstname: firstItem?.shippingAddress.firstName
                });
                setLoadingCover(false);
                addDataLayer('magic_link_page_view', {
                  sku: currentProductData.checkoutData.product_id,
                  sub_db_user_id: firstItem?.shippingAddress.id
                });
              });
          })
          .catch(error => {
            console.log(error);
            if (redirectIfAuthFails && !Builder.isEditing) {
              window.location.href = expiredTokenRedirect;
            }
          });
      } catch (error) {
        console.log(error);
        if (redirectIfAuthFails && !Builder.isEditing) {
          window.location.href = expiredTokenRedirect;
        }
      }
    }
  }, [
    subscriptionList,
    setSubscriptionList,
    petlabCampaignTokenKey,
    klaviyoUserIdKey,
    klaviyoCampaignIdKey,
    endpointApiSite,
    setToken,
    redirectIfAuthFails,
    expiredTokenRedirect,
    extraObjects,
    setExtraObjects,
    setLoadingCover,
    currentProductData
  ]);

  const SelectContainer = () => {
    const optionsData = subscriptionList.map(item => {
      const product_name = item.summary;
      const total_price = item.subscriptionAmount.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
      const label = `${product_name} (${currencySymbol}${total_price})`;
      return {
        value: item.id,
        label: label,
        result: item,
        icon: item.image
      };
    });

    const handleSelectChange = ({ result }) => {
      setActiveSubscription(result);
    };

    const { Option, SingleValue } = components;
    const IconOption = props => (
      <Option {...props}>
        <S.OptionImage src={props.data.icon} alt={props.data.label} />
        <S.OptionLabel>{props.data.label}</S.OptionLabel>
      </Option>
    );

    const SingleValue2 = props => (
      <SingleValue {...props}>
        <S.OptionImage src={props.data.icon} alt={props.data.label} />
        <S.OptionLabel>{props.data.label}</S.OptionLabel>
      </SingleValue>
    );

    return (
      <Select
        classNamePrefix="subscriptions-select"
        options={optionsData}
        onChange={handleSelectChange}
        inputProps={{ readOnly: true }}
        isSearchable={false}
        defaultValue={optionsData.filter(
          option => option.value === activeSubscription?.id
        )}
        components={{ Option: IconOption, SingleValue: SingleValue2 }}
      />
    );
  };

  const submitOrder = () => {
    try {
      return axios
        .post(
          `${endpointApiSite}/api/crossell/offer`,
          {
            variantId: parseInt(currentProductData.data.variantId),
            quantity: currentProductData.data.quantity,
            discountCode: subDiscountCode,
            utmString: window.location.search,
            magicLinkUrl: window.location.href,
            subscriptionId: activeSubscription.id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(results => {
          addDataLayer('magic_link_succesful_cross_sell', {
            sku: currentProductData.checkoutData.product_id,
            sub_db_user_id: activeSubscription?.shippingAddress.id,
            sub_id: activeSubscription?.id,
            value: currentProductData.checkoutData.discounted_price
          });
          return results;
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Button = () => {
    const isDisabled =
      activeSubscription.additionalProducts.find(
        item =>
          parseInt(item.variant) === parseInt(currentProductData.data.variantId)
      ) || submitted.includes(activeSubscription.id);

    const handleButtonClick = () => {
      if (Builder.isEditing) return;
      if (!activeSubscription) return;
      if (isDisabled) return;
      setButtonLoading(true);
      setShowConfirmationPopup(true);
      addDataLayer('magic_link_add_to_cart', {
        sku: currentProductData.checkoutData.product_id,
        sub_db_user_id: activeSubscription?.shippingAddress.id,
        sub_id: activeSubscription?.id,
        value: currentProductData.checkoutData.discounted_price
      });
    };

    const _variantExistsMessage =
      variantExistsMessage ||
      `Good news, you already have this product in your subscription! 
      Please visit our <a href="${accountPortalUrl}" target="_blank">Account Portal</a> 
      if you would like to update the quantity of this product or to access even more bespoke offers.`;

    return (
      <>
        <S.Button
          className={isDisabled ? `disabled` : ``}
          onClick={handleButtonClick}
        >
          {buttonLoading ? (
            <img src="/images/three-dots.svg" alt="loading" />
          ) : currentCategory === 'subscriptions' ? (
            `Add to Subscription`
          ) : (
            ` Add to the next Delivery`
          )}
        </S.Button>
        {isDisabled && (
          <S.ExistsMessage
            isCentered={variantExistsMessage}
            dangerouslySetInnerHTML={{ __html: _variantExistsMessage }}
          />
        )}
      </>
    );
  };

  return (
    <>
      {topText && subscriptionList?.length > 1 && (
        <S.TopText>{topText}</S.TopText>
      )}
      <S.Container>
        {!subscriptionList && (
          <S.Loading>
            <img src="/images/three-dots.svg" alt="loading" />
          </S.Loading>
        )}
        {subscriptionList && (
          <>
            {subscriptionList.length > 1 && <SelectContainer />}
            {activeSubscription && (
              <>
                {children ? children : <Button />}
                <ConfirmationPopup
                  submitOrder={submitOrder}
                  activeSubscription={activeSubscription}
                  showConfirmationPopup={showConfirmationPopup}
                  setButtonLoading={setButtonLoading}
                  setShowConfirmationPopup={setShowConfirmationPopup}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                  confirmationPopupPricingAddonOnly={
                    confirmationPopupPricingAddonOnly
                  }
                />
              </>
            )}
          </>
        )}
      </S.Container>
      {loadingCover && !Builder.isEditing && <LoadingCover />}
    </>
  );
};

export default ActiveSubscriptionSelector;
