import React, { useState, useContext } from 'react';
import * as S from './ConfirmationPopup.styles';
import FunnelContext from '../../../context/FunnelContext';
import Loading from '../../slides/Loading/Loading';
import Modal from '../../Modal/Modal';

const ConfirmationPopup = props => {
  const {
    submitOrder,
    activeSubscription,
    setButtonLoading,
    showConfirmationPopup,
    setShowConfirmationPopup,
    submitted,
    setSubmitted,
    confirmationPopupPricingAddonOnly = false
  } = props;

  const { currentCategory } = useContext(FunnelContext);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [upgradeData, setUpgradeData] = useState({
    totalAmount: 0.0,
    shippingDate: '#/#/####'
  });

  const { currency, subscription, currentTubIndex } = useContext(FunnelContext);
  const currencySymbol = currency?.symbol || '$';

  const currentProductData = subscription.products?.find(
    (_, index) => index === currentTubIndex
  );

  const taxRate =
    activeSubscription?.totalAmount / activeSubscription?.subscriptionAmount -
    1;
  let subTotal =
    activeSubscription?.subscriptionAmount +
    currentProductData?.checkoutData?.discounted_price;

  if (confirmationPopupPricingAddonOnly) {
    subTotal = currentProductData?.checkoutData?.discounted_price;
  }

  const newTax = subTotal * taxRate;
  const newTotal = subTotal + newTax;
  const handleClick = () => {
    setLoading(true);
    try {
      submitOrder()
        .then(results => {
          setUpgradeData(results?.data);
          setSuccess(true);
          setLoading(false);
          setSubmitted([...submitted, activeSubscription.id]);
        })
        .catch(err => {
          console.log(err);
          setSuccess(false);
          setLoading(false);
        });
    } catch {
      setSuccess(false);
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowConfirmationPopup(false);
    setButtonLoading(false);
    setSuccess(false);
    setLoading(false);
  };

  const ConfirmationBody = () => (
    <>
      <S.Header>
        <S.CloseButton onClick={closeModal}>×</S.CloseButton>
        Please Confirm
      </S.Header>
      <S.Body>
        <S.Details>
          <S.ProductImage src={currentProductData?.checkoutData?.image} />
          <S.ProductDetails>
            <S.ProductTitle>
              {currentProductData?.checkoutData?.title}
            </S.ProductTitle>
            <S.ProductQuantity>
              {currentProductData?.display_title}
            </S.ProductQuantity>
            <S.ProductAmount>
              {currencySymbol}
              {currentProductData?.checkoutData?.discounted_price.toFixed(2)}
            </S.ProductAmount>
          </S.ProductDetails>
        </S.Details>
        <S.Summary>
          <S.SummaryItem>
            <S.SummaryItemTitle>Subtotal</S.SummaryItemTitle>
            <S.SummaryItemAmount>
              {currencySymbol}
              {subTotal.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </S.SummaryItemAmount>
          </S.SummaryItem>
          <S.SummaryItem>
            <S.SummaryItemTitle>Tax</S.SummaryItemTitle>
            <S.SummaryItemAmount>
              {currencySymbol}
              {newTax.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </S.SummaryItemAmount>
          </S.SummaryItem>
          <S.SummaryItem>
            <S.SummaryItemTitle className="total">Total</S.SummaryItemTitle>
            <S.SummaryItemAmount className="total">
              {currencySymbol}
              {newTotal.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </S.SummaryItemAmount>
          </S.SummaryItem>
        </S.Summary>
        {currentCategory === 'subscriptions' ? (
          <S.Message>
            This will be added to your next order on{' '}
            {activeSubscription?.nextDelivery} and will be billed monthly with
            your existing subscription
          </S.Message>
        ) : (
          <S.Message>
            This will be added to your next subscription order on{' '}
            {activeSubscription?.nextDelivery} only once, and will not be billed
            monthly.
          </S.Message>
        )}
        <S.Button onClick={handleClick} className={loading ? `` : ``}>
          {loading ? (
            <img src="/images/three-dots.svg" alt="loading" />
          ) : (
            `Confirm & Proceed`
          )}
        </S.Button>
      </S.Body>
    </>
  );

  const SuccessBody = () => (
    <>
      <S.Body className="no-header">
        <S.CloseButton onClick={closeModal} className="success">
          ×
        </S.CloseButton>
        <S.Animation>
          <Loading design="dog-catch" />
        </S.Animation>
        <S.SuccessTitle>Subscription Added!</S.SuccessTitle>
        <S.Details>
          <S.ProductImage src={currentProductData?.checkoutData?.image} />
          <S.ProductDetails className="success">
            <span>
              {currentProductData?.checkoutData?.quantity} x{' '}
              {currentProductData?.checkoutData?.title}
            </span>{' '}
            will be added to your next{' '}
            <span>
              {activeSubscription?.quantity} x {activeSubscription?.name}
            </span>{' '}
            shipment ({upgradeData?.shippingDate})
          </S.ProductDetails>
        </S.Details>
        <S.Summary className="success">
          <S.SummaryItem className="success">
            <S.SummaryItemTitle>Next Rebill Total</S.SummaryItemTitle>
            <S.SummaryItemAmount className="success">
              {currencySymbol}
              {upgradeData?.totalAmount.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </S.SummaryItemAmount>
          </S.SummaryItem>
        </S.Summary>
      </S.Body>
    </>
  );

  return (
    <>
      {showConfirmationPopup && (
        <Modal>
          <S.Container>
            <S.Modal>
              {success ? <SuccessBody /> : <ConfirmationBody />}
            </S.Modal>
          </S.Container>
          <S.Wrapper
            onClick={() => {
              setButtonLoading(false);
              setShowConfirmationPopup(false);
            }}
            className={showConfirmationPopup ? `active` : ``}
          />
        </Modal>
      )}
    </>
  );
};

export default ConfirmationPopup;
